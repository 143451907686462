/* stylelint-disable declaration-no-important, selector-max-attribute, selector-max-specificity, selector-no-qualifying-type */
import { THEME_CONSTANTS } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import { css } from 'styled-components';

export default ({ theme }) => css`
    font-family: ${theme.fonts.body};

    section {
        border-style: solid;
        border-color: ${THEME_CONSTANTS.COLORS.global.grey200};
        border-width: 0.1rem 0.1rem 0;
        ${cssSystem({
            paddingY: 'sm',
            paddingX: ['xs', 'sm']
        })}

        &:first-of-type {
            padding-bottom: ${theme.space.sm};
        }

        &:last-of-type {
            border-width: 0.1rem;
        }

        & + div {
            margin-top: ${theme.space.xs};
        }
    }

    section:nth-of-type(even) {
        background-color: ${THEME_CONSTANTS.COLORS.global.grey50};
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: ${theme.fontWeights.semiBold};
    }

    h1,
    h2,
    h3 {
        ${theme.components.heading.base};
    }

    h1 {
        ${theme.components.heading.large};
    }

    h2 {
        ${theme.components.heading.medium};
    }

    h3 {
        ${theme.components.heading.small};
        color: ${theme.agreement.heading4};
    }

    h4 {
        ${theme.components.text.large};
        margin-top: ${theme.space.md};
        margin-bottom: ${theme.space.sm};
    }

    h5 {
        ${theme.components.text.medium};
        margin-top: ${theme.space.sm};
        margin-bottom: ${theme.space.xs};
    }

    h6 {
        ${theme.components.text.small};
        margin-top: ${theme.space.xs};
        margin-bottom: 0;
    }

    section > h4:first-child:first-of-type,
    section > h5:first-child:first-of-type,
    section > h6:first-child:first-of-type {
        margin-top: 0;
    }

    p,
    span,
    li,
    caption,
    th,
    td,
    strong {
        ${theme.components.text.base};
        ${theme.components.text.small};
    }

    caption,
    th,
    td {
        text-align: left;
    }

    caption,
    th,
    strong {
        font-weight: ${theme.fontWeights.medium};
    }

    p,
    caption {
        margin-bottom: ${theme.space.xs};
    }

    ul,
    ol {
        ${theme.components.list.base};
    }

    ul {
        margin-left: ${theme.space.sm};

        li {
            list-style-type: initial;
        }
    }

    li {
        ${theme.components.list.listItem};
    }

    table,
    p {
        margin-bottom: ${theme.space.xs};

        &:last-child {
            margin-bottom: 0;
        }

        & + table {
            margin-top: ${theme.space.xs};
        }
    }

    a {
        ${theme.components.link.base};
        ${theme.components.link.small};
        ${theme.components.link.dark};

        position: relative;
        background: ${`url("${theme.components.markdown.assets.newTab.dark}") right top no-repeat`};
        background-size: 1.6rem 1.6rem;
        padding-right: ${theme.space.md};
        background-position: right;

        span {
            position: absolute;
            overflow: hidden;
            clip: rect(0.1rem, 0.1rem, 0.1rem, 0.1rem);
            width: 0.1rem !important;
            height: 0.1rem !important;
            padding: 0 !important;
            border: 0 !important;
        }
    }

    th,
    td {
        padding: 0.6rem 0.8rem;
    }

    table {
        width: 100%;
        table-layout: fixed;
        border: 0.1rem solid ${THEME_CONSTANTS.COLORS.global.grey200};
    }

    table.block {
        border: none;
    }

    tr {
        &:first-of-type th[scope='row'],
        &:first-of-type th[scope='row'] + td,
        &:not(:first-of-type):nth-of-type(odd) {
            background-color: ${THEME_CONSTANTS.COLORS.global.grey50};
        }

        &:not(:first-of-type):nth-of-type(even) {
            background-color: ${THEME_CONSTANTS.COLORS.global.white};
        }
    }

    th {
        &[scope='col'] {
            background-color: ${THEME_CONSTANTS.COLORS.global.grey200};
        }

        span {
            display: block;
            ${theme.components.text.small};
        }
    }

    td ~ th {
        background-color: initial !important;
    }

    img {
        width: 100%;
        max-width: 25rem;
    }

    hr {
        margin: ${theme.space.sm} 0;
    }
`;
