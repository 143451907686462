const addSParameterToLinkUrls = ({ links, sParameter }) =>
    links.map((link) => {
        const url = new URL(link.url);
        if (sParameter) {
            url.searchParams.set('s', sParameter);
        }
        return {
            ...link,
            url: url.toString()
        };
    });

export default addSParameterToLinkUrls;
