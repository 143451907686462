import { THEME_CONSTANTS, capitalOneTheme } from '@cof/plastic-components';

export default {
    ...capitalOneTheme.components.header,
    colors: {
        background: {
            mobile: THEME_CONSTANTS.COLORS.capitalOne.blue750,
            desktop: THEME_CONSTANTS.COLORS.capitalOne.blue750
        },
        border: {
            mobile: THEME_CONSTANTS.COLORS.capitalOne.blue750,
            desktop: THEME_CONSTANTS.COLORS.capitalOne.blue750
        },
        aggregators: {
            background: THEME_CONSTANTS.COLORS.global.white,
            border: THEME_CONSTANTS.COLORS.global.white
        }
    }
};
