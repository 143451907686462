export const EVENTS = {
    MODAL_OPEN: 'agreementModalOpen',
    CHECKBOX_CHANGE: 'agreementCheckboxChange',
    AGREEMENT_SUBMISSION: 'agreementSubmission',
    AGREEMENT_NEW_SESSION: 'agreementNewSession',
    EXPIRY_PAGE_SHOW: 'expiryPageShown',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3',
    GDPR_PREF_ALLOWS_2_3: 'GDPR Pref Allows 2,3'
};

const pushToDataLayer = (values) => {
    if (!Object.values(EVENTS).includes(values.event)) {
        throw new Error('Unknown event');
    }
    if (window.dataLayer) {
        window.dataLayer.push(values);
    }
};

export default pushToDataLayer;
