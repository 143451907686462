import { css } from 'styled-components';

export default () => css`
    appearance: none;
    border: 0;
    padding: 0;
    background: transparent;
    display: inline-flex;
    align-items: center;
`;
