/* stylelint-disable selector-max-attribute, declaration-no-important, max-nesting-depth, selector-max-specificity, selector-max-id */
import PropTypes from 'prop-types';
import { createContext, useState, useRef, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

export const PrintContext = createContext();

export const StyledHtml = styled.div`
    ${({ theme }) => theme.components.disclosuresHtml};
`;

StyledHtml.displayName = 'StyledHtml';

export const PrintContainer = styled.div`
    display: none;
    @media print {
        display: block;
        h1,
        h2,
        h3,
        h4,
        ul,
        p,
        table {
            break-inside: avoid;
            break-after: always;
        }
    }
`;

PrintContainer.displayName = 'PrintContainer';

const PrintProvider = ({ children }) => {
    const [printHtml, setPrintHtml] = useState();
    const [printCount, setPrintCount] = useState(0);
    const printContainerRef = useRef();

    const updatePrintHtml = (html) => {
        setPrintHtml(html);
        setPrintCount((prevState) => prevState + 1);
    };

    // when this hook is only dependent on changes to printHtml, window.print doesn't get called on subsequent print button clicks.
    // adding a dependency for printCount means we can force a re-render and therefore run the useEffect function.
    useEffect(() => {
        if (printHtml && printContainerRef.current) {
            window.print();
        }
    }, [printHtml, printCount]);

    return (
        <PrintContext.Provider value={updatePrintHtml}>
            {children}
            {createPortal(
                <PrintContainer ref={printContainerRef}>
                    <StyledHtml dangerouslySetInnerHTML={{ __html: printHtml }} />
                </PrintContainer>,
                document.body
            )}
        </PrintContext.Provider>
    );
};

PrintProvider.propTypes = {
    children: PropTypes.node
};

export default PrintProvider;
