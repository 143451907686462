import { mergeDeepRight } from 'ramda';

import { ERROR_TYPE } from '../../utilities/constants';
import capitalOne from './capitalOne';

const ExternalLinks = {
    QUICKCHECK: 'https://asos-quickcheck.capitalone.co.uk',
    CREDIT_CARDS: 'https://www.asos.com/discover/asos-credit-card',
    CONTACT_US: 'https://www.capitalone.co.uk/asos/contact-us',
    PRIVACY_POLICY: 'https://www.capitalone.co.uk/asos/privacy-policy'
};
const content = {
    DocumentHead: {
        Title: 'Credit Card Application - ASOS',
        Description: 'Complete our application form for a ASOS credit card. Response in 60 seconds.'
    },
    Pages: {
        Landing: {
            HeadingLine1Dynamic: "It's in the bag, {{CUSTOMER_NAME}}!",
            HeadingLine2: 'Finish applying below 👇'
        },
        TechnicalError: {
            [ERROR_TYPE.CLIENT_ERROR]: {
                Button: {
                    URL: ExternalLinks.QUICKCHECK
                }
            },
            [ERROR_TYPE.LOAD_AGREEMENT]: {
                Button: {
                    URL: ExternalLinks.QUICKCHECK
                }
            }
        },
        TimedOut: {
            Button: {
                URL: ExternalLinks.QUICKCHECK
            }
        }
    },

    Sections: {
        Product: {
            MoreInformation:
                'Card provided by Capital One. For more information, check the {{SUMMARY_BOX}} and {{FAQs}}.'
        },
        CheckDetails: {
            Title: 'Check your details are 100% right',
            DataPlayback: {
                BTAmount: 'balance transfer requested',
                BornOn: 'born on',
                Income: 'pre-tax yearly income',
                OtherIncome: 'other household income',
                Dependents: 'financial dependents',
                CashAdvance: {
                    Outcome: {
                        Yes: 'Interested',
                        No: 'Not interested'
                    },
                    Label: 'in a cash advance'
                },
                At: 'at',
                Contribution: 'rent or mortgage contribution',
                YearsAtAddress: 'at this address',
                PreviousAddress: 'Previously at'
            }
        },
        Preferences: {
            Title: 'Pick your preferences',
            MonthlyStatement: {
                Heading: 'Go paperless',
                Label: 'Sign up to paperless statements'
            }
        },
        UsingYourCard: {
            Button: 'Download or print section'
        },
        OtherThings: {
            Paragraph3: {
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            }
        },
        Finish: {
            Title: 'How we use your information',
            Paragraph1: 'When you submit your application, we use your info to:',
            ListItem1:
                "Check your eligibility again with the credit reference agencies and let them know you've applied for a credit card. This will go on your credit file.",
            ListItem2:
                "Do our fraud and identity checks. To do this, we share your information with fraud prevention agencies and other parties. Depending on what comes back, there's a small chance we won't be able to open your account.",
            Paragraph2: "Then when you've got your card, we use it to:",
            ListItem3:
                'Stay up to date with your full financial health. We do this by checking your credit score from time to time. This helps us to decide things such as when to offer you a credit limit increase.',
            Paragraph3: {
                Text: 'For more about how we use your information and your data protection rights, check our {{PRIVACY_POLICY}}',
                PrivacyPolicy: 'Privacy Policy',
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            },
            MarketingPreference: {
                Text: 'We may get in touch about products, services and relevant information by post, email or SMS. To say no, untick the box.'
            },
            Button: {
                Text: 'Finish my application'
            }
        },
        BalanceTransfer: {
            SubHeading: 'Would you like to transfer an existing balance to your asos Classic Card now?',
            Paragraph2:
                'If you would like to transfer an existing balance to your new asos Classic Card, please enter your current card details below. Minimum transfer amount is £50'
        }
    },
    Modals: {
        FAQs: {
            AddendumLine1: `If we haven't been able to answer your question above, then please call us on {{LINK}}.`,
            PhoneNumber: '03444 815 826',
            Questions: {
                0: {
                    Link: {
                        Url: ExternalLinks.CREDIT_CARDS
                    }
                },
                1: {
                    Link: {
                        Url: ExternalLinks.CREDIT_CARDS
                    }
                },
                7: {
                    Link: {
                        Url: ExternalLinks.CONTACT_US
                    }
                }
            }
        },
        EditStage: {
            TriggerText: "Change '{{name}}'"
        }
    }
};

export default mergeDeepRight(capitalOne, content);
