import { BRANDS, BrandedFooter, footerContent as plasticFooterContent } from '@cof/plastic-components';

import { useTheme } from 'styled-components';
import addSParameterToLinkUrls from '../../utilities/addSParameterToLinkUrls';
import { useJourneyContext } from '../../data/JourneyContext';

const Footer = () => {
    const theme = useTheme();
    const { sParameter } = useJourneyContext();
    const footerContent = Object.entries(plasticFooterContent).reduce(
        (updatedFooter, [footerBrand, content]) => ({
            ...updatedFooter,
            [footerBrand === BRANDS.CAPITAL_ONE ? 'capitalOne' : footerBrand.toLowerCase()]: {
                links: addSParameterToLinkUrls({ links: content.links, sParameter }),
                copyright: content.copyright
            }
        }),
        {}
    );

    return (
        <BrandedFooter allowList={null} footerContent={footerContent} variant={theme.agreement?.footer?.background} />
    );
};

export default Footer;
