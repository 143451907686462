import { THEME_CONSTANTS } from '@cof/plastic-components';
import cssSystem from '@styled-system/css';
import { css, keyframes } from 'styled-components';

const animateOpacity = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

export default {
    focusCatcher: css`
        position: absolute; /* ensure it doesn't cause any unwanted layout issues */
    `,
    backdrop: css`
        /* z-index set to ensure it overlays all other elements */
        z-index: 9000;
        width: 100%;
        height: 100%;
        position: fixed;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        animation-name: ${animateOpacity};
        animation-duration: 0.2s;
        animation-timing-function: ease-out;
        overflow: scroll;
    `,
    closeButtonContainer: ({ theme }) => css`
        display: flex;
        justify-content: flex-end;
        margin-bottom: ${theme.space.xs};

        @media print {
            display: none;
        }
    `,
    closeButtonIcon: () => css`
        margin-left: 0.2rem;
    `,
    container: ({ theme }) => css`
        position: relative;
        animation-name: ${animateOpacity};
        animation-duration: 0.2s;
        animation-timing-function: ease-out;

        background-color: ${THEME_CONSTANTS.COLORS.global.white};
        max-width: calc(${theme.breakpoints.largeTablet} - (2 * ${theme.space.lg}));
        box-sizing: border-box;

        ${cssSystem({
            marginY: ['sm', 'md', 'lg'],
            marginX: ['sm', 'md', 'lg', 'auto'],
            padding: ['sm', 'md', 'lg']
        })}

        @media print {
            display: none;
        }
    `
};
