import { createContext, useState, useMemo } from 'react';

export const ExperimentContext = createContext();

const ExperimentProvider = (props) => {
    const [experiment, setExperiment] = useState('');
    const value = useMemo(() => [experiment, setExperiment], [experiment]);
    return <ExperimentContext.Provider value={value} {...props} />;
};

export default ExperimentProvider;
