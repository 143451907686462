import { useBrand } from '@cof/plastic-components';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import useSessionData from '../../hooks/useSessionData';
import { MISSING_DETAILS_DISABLED_CONSUMERS, isAggregator } from '../../utilities/constants';
import {
    getChannel,
    getPcr,
    getProduct,
    getSParameter,
    getSearchParameter,
    getSessionId,
    getVendorCodeFromUrl
} from '../../utils';
import { useAgreementData } from '..';

const JourneyContext = createContext();

export const JourneyContextProvider = ({ children }) => {
    const { quotation } = useSessionData();
    const sessionId = getSessionId();
    const brand = useBrand();
    const pcr = getPcr(brand);
    const sParameter = getSParameter();
    const product = getProduct();
    const channel = getChannel();
    const campaign = getSearchParameter('campaign');
    const vendorCodeFromUrl = getVendorCodeFromUrl();

    const [agreementData, gettingAgreementData, agreementDataError] = useAgreementData({
        quotation,
        sessionId,
        brand,
        pcr,
        sParameter,
        product,
        channel,
        v: vendorCodeFromUrl,
        campaign
    });

    const consumerName = agreementData?.consumerName;
    const isAggregatorConsumer = consumerName && brand === 'capitalOne' && isAggregator(consumerName);
    const aggregatorConsumerName = isAggregatorConsumer ? agreementData?.consumerName : null;

    const toReturn = {
        loading: gettingAgreementData,
        error: agreementDataError,
        agreementData,
        sessionId,
        pcr,
        sParameter,
        product,
        channel,
        campaign,
        consumerName,
        productId: agreementData?.product?.id,
        vendorCodeFromUrl,
        vendorCode: vendorCodeFromUrl ?? agreementData?.vendorCode,
        aggregatorConsumerName,
        isMissingDetailsEnabled: !MISSING_DETAILS_DISABLED_CONSUMERS.includes(consumerName),
        brand
    };

    return <JourneyContext.Provider value={toReturn}>{children}</JourneyContext.Provider>;
};

export const useJourneyContext = () => useContext(JourneyContext);

JourneyContextProvider.propTypes = {
    children: PropTypes.node.isRequired
};
