export default `
    @media print {
        body {
            height: auto !important;
            overflow: visible !important;
        }

        #root {
            height: auto !important;
        }

        [role='presentation'],
        [role='dialog'] {
            margin: 0 !important;
            padding: 0 !important;
        }

        [role='presentation'] {
            height: auto !important;
            position: relative !important;
            overflow: visible !important;
        }

        h1,
        h2,
        h3,
        h4,
        li,
        p,
        tbody,
        tr {
            break-inside: avoid;
            break-after: always;
        }
    }
`;
