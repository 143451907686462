import { useBrand } from '@cof/plastic-components';
import config from '@cof/ukweb-config/lib/clientConfig';
import PropTypes from 'prop-types';

import { Helmet } from 'react-helmet';

import { withText } from '../../text/textStore';

const DocumentHead = ({ Text: text }) => {
    const { isEnabled } = config.get('iovation');
    const brand = useBrand();

    return (
        <Helmet>
            <link rel="icon" href={`/${brand}_favicon.ico`} />
            <title>{text({ path: 'Title' })}</title>
            {brand.toLowerCase() === 'asos' && <meta name="robots" content="noindex" />}
            <meta name="Description" content={text({ path: 'Description' })} />
            {isEnabled && (
                <script language="javascript">
                    {`
                        window.io_global_object_name = 'IGLOO';
                        window.IGLOO = window.IGLOO || {
                            enable_flash: false,
                            loader: {
                                uri_hook: '/',
                                version: 'general5'
                            }
                        };
                    `}
                </script>
            )}
            {isEnabled && <script type="text/javascript" src="/loader.js" />}
        </Helmet>
    );
};

DocumentHead.propTypes = {
    Text: PropTypes.func.isRequired
};

const DocumentHeadWithText = withText('DocumentHead')(DocumentHead);

DocumentHeadWithText.displayName = 'DocumentHead';

export default DocumentHeadWithText;
