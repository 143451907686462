import { css } from 'styled-components';

const content = () => css`
    display: flex;
    flex-flow: row-reverse wrap;
    position: relative;
    z-index: 1;
`;

const card = ({ theme }) => css`
    max-height: 80%;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    display: block;
    object-fit: contain;

    ${theme.breakpoints.mq.tabletMin} {
        max-height: initial;
    }
`;

const featureContainer = () => css`
    flex: 1 1 50%;
    display: flex;
    flex-flow: column nowrap;
    justify-items: center;
    align-items: flex-start;
`;

const circle = ({ theme }) => css`
    z-index: 0;
    background-color: ${theme.agreement.product.circle};
    border-radius: 50%;
    position: absolute;
    height: 27rem;
    right: -16rem;
    top: -1rem;
    width: 27rem;

    ${theme.breakpoints.mq.tabletMin} {
        height: 40rem;
        right: -15rem;
        top: -2rem;
        width: 40rem;
    }
`;

const celebrationBox = () => css`
    display: none;
`;

export default {
    content,
    featureContainer,
    circle,
    card,
    celebrationBox
};
