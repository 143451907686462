/* eslint complexity: ["error", 7] */
import config from '@cof/ukweb-config/lib/clientConfig';
import { useCallback, useEffect, useState } from 'react';

window.initializeZuko = () => {
    const zukoConfig = config.get('zuko');
    if (zukoConfig.isEnabled && window.Zuko && window.zukoAttributes && !window.zukoInstance) {
        const zukoAttr = window.zukoAttributes;
        const slug = zukoConfig.slug[zukoAttr.brand] || zukoConfig.slug.default || '2664c3eacd83cdf';

        window.zukoInstance = window.Zuko.trackForm({ slug, target: zukoAttr.formEl })
            .setAttribute('formDefinitionId', zukoAttr.formDefinitionId)
            .setAttribute('productId', zukoAttr.productId)
            .setAttribute('vendorCode', zukoAttr.vendorCode)
            .setAttribute('channel', zukoAttr.channel)
            .setAttribute('brand', zukoAttr.brand)
            .setAttribute('balanceTransfer', zukoAttr.balanceTransfer)
            .setAttribute('btGuarantee', zukoAttr.btGuarantee)
            .setAttribute('experimentVariant', zukoAttr.experimentVariant)
            .trackEvent(window.Zuko.FORM_VIEW_EVENT);
    }
};

// eslint-disable-next-line import/prefer-default-export
export const useZuko = ({
    formDefinitionId,
    productId,
    vendorCode,
    channel,
    brand,
    balanceTransfer,
    btGuarantee,
    experimentVariant
}) => {
    const [formEl, setFormEl] = useState(null);
    useEffect(() => {
        // Validate the required attributes exist.
        if (formEl && formDefinitionId && productId && channel && brand) {
            window.zukoAttributes = {
                formEl,
                formDefinitionId,
                productId,
                vendorCode,
                channel,
                brand,
                balanceTransfer: !!balanceTransfer,
                btGuarantee: !!btGuarantee,
                experimentVariant
            };
        }
        // Also called in GTM Zuko tag
        window.initializeZuko();
    }, [
        formEl,
        formDefinitionId,
        productId,
        vendorCode,
        channel,
        brand,
        balanceTransfer,
        btGuarantee,
        experimentVariant
    ]);

    const trackCompletionEvent = useCallback(() => {
        if (typeof window?.zukoInstance?.trackEvent === 'function') {
            try {
                window.zukoInstance.trackEvent(window.Zuko.COMPLETION_EVENT);
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
    }, []);

    const trackEditEvent = useCallback(() => {
        if (typeof window?.zukoInstance?.trackEvent === 'function') {
            try {
                window.zukoInstance.trackEvent('Clicked edit details');
            } catch (e) {
                // eslint-disable-next-line no-console
                console.log(e);
            }
        }
    }, []);

    return { setFormEl, trackCompletionEvent, trackEditEvent };
};

export const trackGenericEvent = (eventName) => {
    if (typeof window?.zukoInstance?.trackEvent === 'function') {
        try {
            window.zukoInstance.trackEvent(eventName);
        } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
        }
    }
};
