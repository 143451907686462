const useBrandStrategy = () => {
    const { search, hostname } = window.location;

    if (hostname === 'localhost' || hostname === 'bs-local.com') {
        const query = new URLSearchParams(search);
        return query.get('partner');
    }

    if (hostname.indexOf('-') > -1) {
        return hostname.split('-')[0];
    }

    return null;
};

export default useBrandStrategy;
