import dataPlaybackStyles from './dataPlaybackStyles';
import disclosuresHtml from './disclosuresHtml';
import header from './headerStyles';
import customLegend from './legendStyles';
import linkButton from './linkButtonStyles';
import internalModal from './modalStyles';
import product from './section/product';

export default {
    disclosuresHtml,
    header,
    customLegend,
    linkButton,
    internalModal,
    section: {
        product
    },
    dataPlayback: dataPlaybackStyles
};
