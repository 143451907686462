import { capitalOneTheme } from '@cof/plastic-components';
import { css } from 'styled-components';

import printStyle from '../shared/printStyle';
import customComponents from './componentStyles';
import pages from './pages';

const mergedComponents = {
    ...capitalOneTheme.components,
    ...customComponents
};

const mergedGlobalStyles = css`
    ${capitalOneTheme.globalStyles}
    ${printStyle}
`;

export default {
    ...capitalOneTheme,
    globalStyles: mergedGlobalStyles,
    components: mergedComponents,
    ...pages
};
