import { THEME_CONSTANTS } from '@cof/plastic-components';

const { COLORS } = THEME_CONSTANTS;

export default {
    colors: {
        styledParagraph: COLORS.global.white,
        styledText: COLORS.global.white,
        styledTextLabel: COLORS.global.white
    }
};
