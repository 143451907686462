import styled, { css } from 'styled-components';

import { BaseLogo } from '@cof/plastic-components';

import CompareTheMarket from './compare-the-market.svg?react';
import CreditKarma from './creditkarma-logo.svg?react';

const StyledCLRSImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 188px;
            height: 25px;
        }
    `}
`;

const StyledMSMImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 57px;
            height: 60px;
        }
    `}
`;

const StyledTOTMImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 155px;
            height: 25px;
        }
    `}
`;

const StyledUSWCImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 135px;
            height: 40px;
        }
    `}
`;

const StyledMCUImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 167px;
            height: 35px;
        }
    `}
`;

const StyledEXPRImage = styled.img`
    ${({ theme }) => css`
        ${theme.breakpoints.mq.tabletMin} {
            width: 107px;
            height: 35px;
        }
    `}
`;

const aggregatorsLogoMap = {
    MSM: (
        <StyledMSMImage
            src="/assets/images/moneysupermarket-logo.webp"
            height="46"
            width="48"
            alt="MoneySupermarket logo"
            data-qa-id="money-supermarket-logo"
        />
    ),
    CLRS: (
        <StyledCLRSImage
            src="/assets/images/clearscore-logo.webp"
            height="15"
            width="113"
            alt="ClearScore logo"
            data-qa-id="clear-score-logo"
        />
    ),
    TOTM: (
        <StyledTOTMImage
            src="/assets/images/totally-money-logo.webp"
            height="15"
            width="93"
            alt="Totally Money logo"
            data-qa-id="totally-money-logo"
        />
    ),
    CTM: (
        <BaseLogo
            RenderedLogo={CompareTheMarket}
            height={[25, null, 40]}
            width={[141, null, 226]}
            logoProps={{ 'aria-label': 'Compare the Market logo' }}
            data-qa-id="compare-the-market-logo"
        />
    ),
    USWC: (
        <StyledUSWCImage
            src="/assets/images/uswitch-logo.webp"
            height="25"
            width="85"
            alt="uSwitch logo"
            data-qa-id="uswitch-logo"
        />
    ),
    MCU: (
        <StyledMCUImage
            src="/assets/images/money-logo.webp"
            height="20"
            width="95"
            alt="Money COUK logo"
            data-qa-id="money-couk-logo"
        />
    ),
    EXPR: (
        <StyledEXPRImage
            src="/assets/images/experian-logo.webp"
            height="20"
            width="61"
            alt="Experian logo"
            data-qa-id="experian-logo"
        />
    ),
    CK: (
        <BaseLogo
            RenderedLogo={CreditKarma}
            height={[15, null, 35]}
            width={[62, null, 97]}
            logoProps={{ 'aria-label': 'Credit Karma logo' }}
            data-qa-id="credit-karma-logo"
        />
    )
};

export default aggregatorsLogoMap;
