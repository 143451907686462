import { Header as PlasticHeader } from '@cof/plastic-components';
import PropType from 'prop-types';

import styled, { css } from 'styled-components';
import LogoComponent from './LogoComponent';
import { useJourneyContext } from '../../data/JourneyContext';
import { useFlexibleUIExperiment } from '../../hooks/useExperiment/useFlexibleUIExperiment';

const hasAggregatorLogo = (consumerName) =>
    ['MSM', 'CLRS', 'TOTM', 'CTM', 'USWC', 'MCU', 'EXPR', 'CK'].includes(consumerName);

export const StyledHeader = styled(PlasticHeader)`
    ${({
        theme: {
            components: { header },
            space
        },
        showAggregatorLogo,
        flexibleUI
    }) => css`
        background-color: ${showAggregatorLogo
            ? header.colors.aggregators.background
            : header.colors.background.desktop};
        border: ${showAggregatorLogo ? header.colors.aggregators.border : header.colors.border.desktop};
        padding: ${flexibleUI ? `0px ${space.sm}` : null};
    `};
`;

const Header = () => {
    const { aggregatorConsumerName } = useJourneyContext();
    const showAggregatorLogo = hasAggregatorLogo(aggregatorConsumerName);
    const flexibleUI = useFlexibleUIExperiment();

    return (
        <StyledHeader showAggregatorLogo={showAggregatorLogo} flexibleUI={flexibleUI}>
            <LogoComponent showAggregatorLogo={showAggregatorLogo} flexibleUI={flexibleUI} />
        </StyledHeader>
    );
};

Header.propTypes = {
    appForm: PropType.bool
};

export default Header;
