import { mergeDeepRight } from 'ramda';

import { ERROR_TYPE } from '../../utilities/constants';
import capitalOne from './capitalOne';

const ExternalLinks = {
    QUICKCHECK: 'https://www.oceanfinance.co.uk/credit-cards',
    CREDIT_CARDS: 'https://www.oceanfinance.co.uk/credit-cards',
    CONTACT_US: 'https://www.capitalone.co.uk/ocean/contact-us',
    PRIVACY_POLICY: 'https://www.capitalone.co.uk/ocean/privacy-policy'
};
const content = {
    DocumentHead: {
        Title: 'Credit Card Application - Ocean Finance',
        Description: 'Complete our application form for an Ocean credit card. Response in 60 seconds.'
    },
    Sections: {
        OtherThings: {
            Paragraph3: {
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            }
        },
        Finish: {
            Paragraph3: {
                PrivacyPolicyLink: ExternalLinks.PRIVACY_POLICY
            }
        },
        BalanceTransfer: {
            SubHeading: 'Would you like to transfer an existing balance to your Ocean credit card now?',
            Paragraph2:
                'If you would like to transfer an existing balance to your new Ocean credit card, please enter your current card details below. Minimum transfer amount is £50'
        }
    },
    Pages: {
        TechnicalError: {
            [ERROR_TYPE.CLIENT_ERROR]: {
                Button: {
                    URL: ExternalLinks.QUICKCHECK
                }
            },
            [ERROR_TYPE.LOAD_AGREEMENT]: {
                Button: {
                    URL: ExternalLinks.QUICKCHECK
                }
            }
        },
        TimedOut: {
            Button: {
                URL: ExternalLinks.QUICKCHECK
            }
        }
    },
    Modals: {
        FAQs: {
            AddendumLine1: `If we haven't been able to answer your question above, then please call us on {{LINK}}.`,
            PhoneNumber: '0800 952 0260',
            Questions: {
                0: {
                    Link: {
                        Url: ExternalLinks.CREDIT_CARDS
                    }
                },
                1: {
                    Link: {
                        Url: ExternalLinks.CREDIT_CARDS
                    }
                },
                7: {
                    Link: {
                        Url: ExternalLinks.CONTACT_US
                    }
                }
            }
        }
    }
};

export default mergeDeepRight(capitalOne, content);
