import { css } from 'styled-components';

export default ({ theme, size, variant }) => css`
    ${theme.components.text.base};
    ${theme.components.text.paragraph};
    ${theme.components.text[size]}
    ${theme.components.text[variant]}

    font-weight: ${theme.fontWeights.medium};
`;
