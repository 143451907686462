import { useEffect, useState } from 'react';

const useSessionData = () => {
    const [quotation, setQuotation] = useState(null);
    useEffect(() => {
        const quotationStoredID = sessionStorage.getItem('quotation');
        const queryParams = new URLSearchParams(window.location.search);
        const quotationID = queryParams.get('quotation');
        if (quotationStoredID !== quotationID) {
            sessionStorage.clear();
            sessionStorage.setItem('quotation', quotationID);
        }
        setQuotation(quotationID);
    }, []);
    return { quotation };
};

export default useSessionData;
