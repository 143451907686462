export const EVENTS = {
    MODAL_OPEN: 'Cookie modal open',
    BANNER_OPEN: 'Cookie banner open',
    GDPR_ACCEPT_ALL: 'GDPR Accept All',
    GDPR_REJECT_ALL: 'GDPR Reject All',
    GDPR_PREF_ALLOWS_1: 'GDPR Pref Allows 1',
    GDPR_PREF_ALLOWS_2: 'GDPR Pref Allows 2',
    GDPR_PREF_ALLOWS_3: 'GDPR Pref Allows 3'
};

export const addNewRelicPageAction = (actionName, actionObject) => {
    window.newrelic?.addPageAction(actionName, actionObject);
    return true;
};

export const addNewRelicError = (error, customAttributes) => {
    window.newrelic?.noticeError(error, customAttributes);
    return true;
};

export const setNewRelicAttribute = (attribute, value) => {
    window.newrelic?.setCustomAttribute(attribute, value);
    return true;
};

export const sendNewRelicEvent = (eventText) => {
    window.newrelic?.interaction().setName(eventText).save();
    return true;
};
