/* eslint-disable no-restricted-syntax, no-await-in-loop */

const str2ab = (str) => {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i += 1) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
};

const importPublicKey = async (pem) => {
    const pemHeader = '-----BEGIN PUBLIC KEY-----';
    const pemFooter = '-----END PUBLIC KEY------';
    const pemContents = pem.substring(pemHeader.length, pem.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    const binaryDer = str2ab(binaryDerString);

    return window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256'
        },
        true,
        ['encrypt']
    );
};

export default async (pans, key) => {
    let encryptedPayload = {};
    if (!key) throw new Error('PAN public key is undefined');

    const cryptoKey = await importPublicKey(key);

    for (const [index, pan] of Object.entries(pans)) {
        if (typeof pan.cardNumber !== 'string' || pan.cardNumber === '') {
            encryptedPayload = []; // no PANS provided, return empty array
            break;
        }
        const encrypted = await window.crypto.subtle.encrypt({ name: 'RSA-OAEP' }, cryptoKey, str2ab(pan.cardNumber));
        encryptedPayload[`BT.${index}`] = {
            cardNumber: btoa(String.fromCharCode(...new Uint8Array(encrypted))),
            amount: pan.amount
        };
    }

    return encryptedPayload;
};
