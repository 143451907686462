import template from '@cof/acq-text-ukweb/lib/plugins/transformers/template';
import {
    brandProviderFactory,
    useBrand,
    globalStyles,
    styleReset,
    themeProviderFactory
} from '@cof/plastic-components';
import '@cof/plastic-components/fonts.css';
import PropTypes from 'prop-types';

import { createRoot } from 'react-dom/client';
import { createGlobalStyle } from 'styled-components';

import App from './App';
import DocumentHead from './components/DocumentHead';
import ExperimentProvider from './components/ExperimentProvider';
import { JourneyContextProvider } from './data/JourneyContext';
import useBrandStrategy from './hooks/useBrandStrategy';
import * as text from './text/brands';
import { TextStore } from './text/textStore';
import jsx from './text/transformers/jsx';
import capitalOneTheme from './themes/capitalOne';
import './themes/shared/normalize.css';
import { BRANDS } from './utilities/constants';

/* stylelint-disable selector-max-id, selector-max-specificity */
const GlobalStyle = createGlobalStyle`
    ${styleReset}
    ${globalStyles}
    html, body, #root{
        height: 100%;
    }
`;
/* stylelint-enable selector-max-id, selector-max-specificity */
const brandContent = {
    capitalOne: text.capitalOne,
    luma: text.luma,
    littlewoods: text.littlewoods,
    ocean: text.ocean,
    thinkmoney: text.thinkmoney,
    postoffice: text.postoffice,
    very: text.very,
    asos: text.asos
};

const BrandProvider = brandProviderFactory({
    brandProviderStrategy: useBrandStrategy,
    defaultBrand: 'capitalOne',
    brandAllowList: ['capitalOne', 'luma', 'littlewoods', 'ocean', 'thinkmoney', 'postoffice', 'very', 'asos']
});

const ThemeProvider = themeProviderFactory({
    themeLoader: async (brand) => import(`./themes/${brand}/index.js`),
    useBrandSelector: () => useBrand(BRANDS),
    defaultBrand: 'capitalOne',
    defaultTheme: capitalOneTheme
});

const BrandTextStore = ({ children }) => {
    const brand = useBrand(BRANDS);
    return (
        <TextStore
            transformers={{
                template,
                jsx
            }}
            value={brandContent[brand]}
        >
            {children}
        </TextStore>
    );
};

BrandTextStore.propTypes = {
    children: PropTypes.node.isRequired
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <BrandProvider>
        <ThemeProvider>
            <ExperimentProvider>
                <JourneyContextProvider>
                    <BrandTextStore>
                        <GlobalStyle />
                        <DocumentHead />
                        <App />
                    </BrandTextStore>
                </JourneyContextProvider>
            </ExperimentProvider>
        </ThemeProvider>
    </BrandProvider>
);
